// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// const BaseUrl ="https://localhost:44361/api";
//const BaseUrl = "https://RailFleet-dev.basf.com/api";
const BaseUrl = "./api";
// const BaseUrl = "https://RailFleet-qa.basf.com/api";
//const BaseUrl = "https://egrade-demo-1.azurewebsites.net/api";
//const BaseUrl = "https://RailFleet.basf.com/api";
//const BaseUrl = "https://egrade-demo-1.azurewebsites.net/api";
//const BaseUrl = "https://RailFleet.basf.com/api";
export const environment = {
  production: false,
  getUsers: `${BaseUrl}/Users/getallusers?BASFUserID=`,
  getUsers_new: `${BaseUrl}/MasterData/getallusers_new?BASFUserID=`,
  getAllSpecs: `${BaseUrl}/MasterData/getallspecs?RcID=`,
  emailAllSpecs: `${BaseUrl}/MasterData/emailAllSpecs?RcID=`,
  updateUser_new:`${BaseUrl}/MasterData/updateUser_new`,
  mailRequest:`${BaseUrl}/Email/mailrequest`,
  getallbus:`${BaseUrl}/MasterData/getallbus?BASFUserID=`,
  getallroles:`${BaseUrl}/MasterData/getallroles?RoleID=`,
  getUserInfo: `${BaseUrl}/Users/getalluserswithrole?BASFUserID=`,
  getUserInfoWithRoles: `${BaseUrl}/Users/getalluserswithrole?BASFUserID=`,
  getUserReportees: `${BaseUrl}/Users/GetUserRepoties?BASFUserID=`,
  getUserReporting: `${BaseUrl}/Users/GetUserRepotingTo?BASFUserID=`,
  updateUserInfo:`${BaseUrl}/Users/updateuserdetails`,
  getallrailfleetData: `${BaseUrl}/RailFleetData/getallrailfleetData`,
  getAllPermittedcommodities: `${BaseUrl}/MasterData/getallpermittedcommodities`,
  GetFleetVsNextRegYr: `${BaseUrl}/MasterData/GetFleetVsNextRegYr`,
  GetFleetVsOwner: `${BaseUrl}/MasterData/GetFleetVsOwner`,
  GetFleetVsExpiration: `${BaseUrl}/MasterData/GetFleetVsExpiration`,
  getAllPackingGroup: `${BaseUrl}/MasterData/getallPackingGroup`,
  getAllHazardClass: `${BaseUrl}/MasterData/getallHazardClass`,
  getAllCarType: `${BaseUrl}/MasterData/getallCarType`,
  getAllDOTAARClass: `${BaseUrl}/MasterData/getAllDOTAARClass`,
  getAllCarBuilder: `${BaseUrl}/MasterData/getallCarBuilder`,
  getAllRailCarUrls:`${BaseUrl}/MasterData/getrailcarurls`,
  getAllDocumentsUrls:`${BaseUrl}/MasterData/getdocumentsurls`,
  updateRailFleetData: `${BaseUrl}/RailFleetData/updaterailfleetdetails`,
  updaterailBulkfleetdetails: `${BaseUrl}/RailFleetData/updaterailBulkfleetdetails`,
  updatePermittedcommodities: `${BaseUrl}/MasterData/updatepermittedcommodities`,
  updatePackinggroup: `${BaseUrl}/MasterData/updatepackinggroup`,
  updatehazardclass: `${BaseUrl}/MasterData/updatehazardclass`,
  updatecarbuilder: `${BaseUrl}/MasterData/updatecarbuilder`,
  updatebumaster:`${BaseUrl}/MasterData/updatebumaster`,
  getSAMLURL: `${BaseUrl}/Saml/GetSamlURL`,
  decryptUserDetails: `${BaseUrl}/Users/decryptUserdetails?decryptUser=`,
  getAttachmentsUrl: `${BaseUrl}/MasterData/getattachmentsurl`,
  updateAttachementsUrl: `${BaseUrl}/MasterData/updateattachmentsurls`,
  updateRailCarUrls: `${BaseUrl}/MasterData/updaterailcarurls`,
  updateDocumentsUrls: `${BaseUrl}/MasterData/updatedocumentsurls`,
  getToken: `${BaseUrl}/Jwt/GetToken?BASFUserID=`,
  insertLoginAudit: `${BaseUrl}/LoginAudit/InsertLoginAuditDetails?BASFUserID=`,
  GetAllNotifications: `${BaseUrl}/AdminNotification/GetAllNotifications`,
  GetCurrentNotifications: `${BaseUrl}/AdminNotification/GetCurrentNotifications`,
  CreateNotifications: `${BaseUrl}/AdminNotification/Create`,
  UpdateNotification: `${BaseUrl}/AdminNotification/UpdateNotification`,


  getRailfleetInvoiceData: `${BaseUrl}/RailFleetInvoice/getRailfleetInvoiceData?invoicetype=`,
  updateBulkInvoiceDetails: `${BaseUrl}/RailFleetInvoice/updateBulkInvoiceDetails`,
  updateMvdAccountUsers:  `${BaseUrl}/RailFleetInvoice/updateMvdAccountUsers?updatedby=`,
  reviewInvoiceDetails: `${BaseUrl}/RailFleetInvoice/reviewInvoiceDetails`,
  getInvoiceHistoricalMaintenance: `${BaseUrl}/InvoiceHistoricalMaintenance/InvoiceHistoricalMaintenance`,
  syncInvoiceHistoricalMaintenance: `${BaseUrl}/InvoiceHistoricalMaintenance/syncInvoiceHistoricalMaintenance`,
};
