import { AfterViewChecked, AfterViewInit, Component, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FilterUtils, SortEvent } from 'primeng/api';
import { RailFleetService } from '../../../services/rail-fleet.service';
import * as XLSX from 'xlsx';
import { stringify } from 'querystring';
import { AllspecsComponent } from '../allspecs/allspecs.component';
import { MatDialog } from '@angular/material/dialog';
import { Table } from 'primeng/table';
import * as FileSaver from 'file-saver';
import { ToastrService } from 'ngx-toastr';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-rail-car-info-selection',
  templateUrl: './rail-car-info-selection.component.html',
  styleUrls: ['./rail-car-info-selection.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class RailCarInfoSelectionComponent implements OnInit, AfterViewInit, AfterViewChecked {
  activeCarsData: any;
  cols: any[];
  sortedCols: any[];
  apiResp: any;
  defaultWidth: '100%';
  viewColHeight: any
  selectedCols = [];
  userId: any;
  userAdmin: boolean = false;
  filteredData: any;
  filteredFields = [];
  tempFilteredData: any;
  selectedValues: any = [];
  bulkEdit = false;
  permittedCommodities = [];
  rowData: any;
  masterAPIResp: any;
  pushingPC: any;
  userInfo: any;
  Arole = 0;
  isEditMode = false
  @ViewChild('dt', { static: false }) dt: Table;
  tableUpdate = false;
  apires: any;
  permittedCommoditiesOpts: any;
  packingGroups: any;
  hazardClasses: any;
  carTypes: any;
  dot_aar_classes: any;
  carBuilders: any;
  filteredCols: any[] = [];
  orgActiveCarsData: any = [];
  constructor(private _railFleetService: RailFleetService, private router: Router, private spinner: NgxSpinnerService, private dialog: MatDialog, private _toastr: ToastrService) { }

  ngOnInit() {
    this.getAllMasterData()
    this._railFleetService.GetFleetVsNextRegYr().subscribe((data) => console.log(data));
    this.userId = sessionStorage.getItem('userId');
    let role = sessionStorage.getItem('role');
    if (role != null && role != undefined && role != '') {
      if (role.includes('Admin')) {
        this.userAdmin = true;
        this.Arole = 1;
      } else {
        this.userAdmin = false;
      }
    } else {
      this.userAdmin = false;
    }
    if (this.userId != null && this.userId != 'undefined' && this.userId != '') {
      this.spinner.show();
        this.cols = [
        { field: 'railcarID', header: 'Railcar ID', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 1, selectedOptions: [], editMode: false },
        { field: 'carStatus', header: 'Car Status', options: [], isShow: true, width: this.defaultWidth, isEditable: true, serialNo: 2, selectedOptions: [], editMode: false },
        { field: 'dueTest', header: 'Due Test', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 3, selectedOptions: [], editMode: false },
        //{ field: 'currentRegCompleted', header: 'Current Reg.Completed(ALLT Data)', isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 4 },
        { field: 'originalRCID', header: 'Original RC ID', options: [], isShow: true, width: this.defaultWidth, isEditable: true, serialNo: 4, selectedOptions: [], editMode: false },
        { field: 'bu', header: 'BU', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 5, selectedOptions: [], editMode: false },
        { field: 'tcc', header: 'TCC', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 6, selectedOptions: [], editMode: false },
        { field: 'contract', header: 'Contract', options: [], isShow: true, width: this.defaultWidth, isEditable: true, serialNo: 7, selectedOptions: [], editMode: false },
        { field: 'fixedAsset', header: 'Fixed Asset', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 8, selectedOptions: [], editMode: false },
        { field: 'locationCode', header: 'Location Code', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 9, selectedOptions: [], editMode: false },
        { field: 'maintenancePlant', header: 'Maintenance Plant', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 10, selectedOptions: [], editMode: false },
        { field: 'assignedLocation', header: 'Assigned Location', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 11, selectedOptions: [], editMode: false },
        { field: 'maintenancePlantLocation', header: 'Maintenance Plant Location', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 12, selectedOptions: [], editMode: false },
        { field: 'fleet', header: 'Fleet', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 13, selectedOptions: [], editMode: false },

        { field: 'permittedCommodity', subfield: 'permittedcommodities', header: 'Permitted Commodities', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 14, selectedOptions: [], editMode: false },
        { field: 'packingGroup', header: 'Packing Group', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 15, selectedOptions: [], editMode: false },
        { field: 'hazardClass', header: 'Hazard Class', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 16, selectedOptions: [], editMode: false },
        //{ field: 'jacketed', header: 'Jacketed (SPECs data)', isShow: true, width: this.defaultWidth, isEditable: true, serialNo: 16 },
        { field: 'carType', header: 'Car Type', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 17, selectedOptions: [], editMode: false },
        { field: 'originalCertofConstruction', header: 'Original Cert. of Construction', options: [], isShow: true, width: this.defaultWidth, isEditable: true, serialNo: 18, selectedOptions: [], editMode: false },
        { field: 'doT_AAR_Class', header: 'DOT/AAR Class', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 19, selectedOptions: [], editMode: false },
        { field: 'carBuilder', header: 'Car Builder', options: [], isShow: true, width: this.defaultWidth, isEditable: true, serialNo: 20, selectedOptions: [], editMode: false },
        { field: 'yearBuilt', header: 'Year Built', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 21, selectedOptions: [], editMode: false },
        { field: 'ageofCar', header: 'Age of Car', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 22, selectedOptions: [], editMode: false },
        { field: 'onHire', header: 'On Hire', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 23, selectedOptions: [], editMode: false },
        { field: 'owner', header: 'Owner', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 24, selectedOptions: [], editMode: false },
        { field: 'rider', header: 'Rider', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 25, selectedOptions: [], editMode: false },
        { field: 'effective', header: 'Effective', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 26, selectedOptions: [], editMode: false },
        { field: 'expiration', header: 'Expiration', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 27, selectedOptions: [], editMode: false },
        { field: 'monthlyDepreciation', header: 'Monthly Depreciation', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 28, selectedOptions: [], editMode: false },
        // { field: 'rent', header: 'Rent', isShow: true, width: this.defaultWidth, isEditable: true, serialNo: 29 },
        // { field: 'rep_Maint', header: 'Rep & Maint.)', isShow: true, width: this.defaultWidth, isEditable: true, serialNo: 30 },
        { field: 'rent', header: 'Monthly Rent', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 29, selectedOptions: [], editMode: false },
        { field: 'leaseTerm', header: 'Lease Term. Mns', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 30, selectedOptions: [], editMode: false },
        { field: 'term', header: 'Term. yr', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 31, selectedOptions: [], editMode: false },
        { field: 'tareWeightLBS', header: 'Tare Weight LBS', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 32, selectedOptions: [], editMode: false },
          { field: 'maxGrossWeight', header: 'Max Gross Weight', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 33, selectedOptions: [], editMode: false },
          { field: 'loadVolumn', header: 'Load Volumen', options: [], isShow: true, width: this.defaultWidth, isEditable: false, serialNo: 34, selectedOptions: [], editMode: false },
        { field: 'comments', header: 'Comments', options: [], isShow: true, width: this.defaultWidth, isEditable: true, serialNo: 35, selectedOptions: [], editMode: false },
        { field: 'buNotes', header: 'BU Notes', options: [], isShow: true, width: this.defaultWidth, isEditable: true, serialNo: 36, selectedOptions: [], editMode: false },
        // { field: 'release', header: 'Release',options:[], isShow: true, width: this.defaultWidth, isEditable: true, serialNo: 33,selectedOptions:[]},
      ];
      this.sortedCols = JSON.parse(JSON.stringify(this.cols));
      this.sortedCols = this.sortedCols.sort((a, b) => {
        return a.header > b.header ? 1 : -1;
      });
      this.sortedCols.forEach(element => {
        if (element.field != 'hazardClass' && element.field != 'packingGroup'
          && element.field != 'carStatus' && element.field != 'dueTest'
          && element.field != 'originalRCID' && element.field != 'permittedCommodity'
          && element.field != 'originalCertofConstruction' && element.field != 'carBuilder'
          && element.field != 'ageofCar' && element.field != 'onHire'
          && element.field != 'monthlyDepreciation' && element.field != 'term'
        )
          this.selectedCols.push(element);
      });
      localStorage.setItem('columns', JSON.stringify(this.selectedCols));
      this.viewColHeight = (window.innerHeight - 190) + "px";
      this._railFleetService.getUserInfo(this.userId).subscribe(response => {
        this.userInfo = response;
        this.getallrailfleetData(this.userInfo[0].userID, this.Arole);
      })
    } else {
      this.router.navigate(['/login']);
    }
  }

  doubleClickCheck(data, col) {
    if (this.userAdmin || col.field == 'buNotes') {
      this.orgActiveCarsData.forEach(x => {
        if (x.railFleetID != data.railFleetID)
          x.editMode = false
      })
      this.selectedCols.forEach(x => {
        if (x.field != col.field)
          x.editMode = false
      })
      let editData = this.orgActiveCarsData.find(x => x.railFleetID == data.railFleetID)
      let colEdit = this.selectedCols.find(x => x.field == col.field)

      if ((!data.editMode && !col.editMode) || (data.editMode && !col.editMode)) {
        editData.editMode = true
        colEdit.editMode = true
        editData.permittedCommodity = this.permittedCommoditiesEdit.filter(pc => data.permittedCommodity
          .find(selectedPc => selectedPc.permittedCommoditiesID === pc.permittedCommoditiesID));
        editData.hazardClassSelected = this.hazardClasses.find(hc => hc.hazardClassID === data.hazardClassID);
        editData.packinggroupSelected = this.packingGroups.find(hc => hc.packinggroup === data.packingGroup);
        editData.carbuilderSelected = this.carBuilders.find(hc => hc.carBuilderID === data.carBuilderID);
      }
      else if (data.editMode && col.editMode) {
        editData.editMode = false
        colEdit.editMode = false
        this.saveAll(data, col.field)
      }
      else {
        editData.editMode = true
      }

    } else {
      this._toastr.warning("Only admin users can edit.");
    }
  }

  saveAll(railCar, col) {
    this.spinner.show();
    const payload = [];
    let railFleetClass = {}
    //single edit
    if (!this.selectedValues.length) {
      railFleetClass = this.generatePayLoad(railCar, railCar.comments, railCar.buNotes, railCar.originalCertofConstruction)
      payload.push(railFleetClass);
    }
    else {
      //multiple comment edit
      this.selectedValues.forEach(x => {
        railFleetClass = this.generatePayLoad(x, col == 'comments' ? railCar.comments : x.comments, col == 'buNotes' ? railCar.buNotes : x.buNotes, col == 'originalCertofConstruction' ? railCar.originalCertofConstruction : x.originalCertofConstruction)
        payload.push(railFleetClass);
      })
    }

    console.log(payload)
    this._railFleetService.updaterailBulkfleetdetails(payload).subscribe(response => {
      this.selectedValues = [];
      this.getallrailfleetData(this.userInfo[0].userID, this.Arole)
    });
  }

  validateNumber(value) {
    let numberExp = new RegExp(/^\d*\.?\d*$/);
    return numberExp.test(value);
  }

  generatePayLoad(railCar, comments = '', buNotes = '', originalCertofConstruction = '') {
    let railComments, railBuNotes = '', railOriginalCertofConstruction = '';
    railComments = comments;
    railBuNotes = buNotes;
    railOriginalCertofConstruction = originalCertofConstruction;
    const railFleetClass = {
      railcarID: railCar.railcarID,
      carStatus: railCar.carStatus,
      dueTest: railCar.dueTest,
      nextRegYr: railCar.nextRegYr,
      currentRegCompleted: railCar.currentRegCompleted,
      originalRCID: railCar.originalRCID,
      bu: railCar.bu,
      buNotes: railBuNotes,
      tcc: railCar.tcc,
      contract: railCar.contract,
      fixedAsset: railCar.fixedAsset,
      locationCode: railCar.locationCode,
      sortField: railCar.sortField,
      maintenancePlant: railCar.maintenancePlant,
      assignedLocation: railCar.assignedLocation,
      fleet: railCar.fleet,
      // permittedCommodities: railCar.permittedCommodities,
      packingGroup: railCar.packinggroupSelected ? railCar.packinggroupSelected.packinggroup : null,
      hazardClass: railCar.hazardClassSelected ? railCar.hazardClassSelected.hazardclass : null,
      jacketed: railCar.jacketed,
      carType: railCar.carType,
      originalCertofConstruction: railCar.originalCertofConstruction,
      doT_AAR_Class: railCar.doT_AAR_Class,
      carBuilder: railCar.carbuilderSelected ? railCar.carbuilderSelected.carbuilder : '',
      yearBuilt: railCar.yearBuilt,
      ageofCar: railCar.ageofCar,
      onHire: railCar.onHire,
      owner: railCar.owner,
      rider: railCar.rider,
      effective: railCar.effective,
      expiration: railCar.expiration,
      monthlyDepreciation: railCar.monthlyDepreciation,
      rent: railCar.rent,
      rep_Maint: railCar.rep_Maint,
      rentalAgreement: railCar.rentalAgreement.toString(),
      leaseTerm: railCar.leaseTerm,
      term: railCar.term,
      tareWeightLBS: railCar.tareWeightLBS,
      maxGrossWeight: railCar.maxGrossWeight,
      comments: railComments,
      release: railCar.release,
      lastUpdatedDate: railCar.lastUpdatedDate,
      permittedCommoditiesID: railCar.permittedCommoditiesID, //
      packingGroupID: railCar.packinggroupSelected ? railCar.packinggroupSelected.packingGroupID : null,
      hazardClassID: railCar.hazardClassSelected ? railCar.hazardClassSelected.hazardClassID : null,
      carBuilderID: railCar.carbuilderSelected ? railCar.carbuilderSelected.carBuilderID : null,
      modifiedBy: this.userId,
      permittedCommodity: railCar.permittedCommodity
    };
    return railFleetClass
  }

  doFilter(val, field, reset?) {
    if (reset) {
      this.filteredCols = [];
    } else if (field != "") {
      let existingFilter = false;
      this.filteredCols.forEach(fc => {
        if (fc.field == field) {
          fc.value = val;
          existingFilter = true;
        }
      });
      if (!existingFilter) {
        this.filteredCols.push({
          name: this.cols.find(c => c.field == field).header,
          field: field,
          value: val
        });
      }
      if (val.length == 0) {
        this.filteredCols = this.filteredCols.filter(fcs => fcs.field !== field);
      }
    }
    this.orgActiveCarsData = JSON.parse(JSON.stringify(this.activeCarsData));
    if (this.filteredCols.length == 0) {
      this.updateMultiSelectDD(this.orgActiveCarsData, 0);
    } else {
      this.filteredCols.forEach((fc, index) => {
        let filteredData = [];
        fc.value.forEach(opt => {
          filteredData = [...filteredData, ...this.orgActiveCarsData.filter(ocd => ocd[fc.field] === opt)];
        });
        this.orgActiveCarsData = JSON.parse(JSON.stringify(filteredData));
        this.updateMultiSelectDD(this.orgActiveCarsData, index);
      });
    }
    localStorage.setItem('columns', JSON.stringify(this.selectedCols));
  }

  checkTheKey(e, data, col) {
    console.log(e);
    if (e.keyCode === 27) {
      this.orgActiveCarsData.forEach(x => x.editMode = false)
    }
    if (e.keyCode === 13) {
      console.log("Clicked on Enter");
      this.doubleClickCheck(data, col);
    }
  }

  tableFocusOut() {
    console.log("table focus out")

  }

  updateMultiSelectDD(rowsData, index) {
    this.pushingPC = this.getMultiSelect(rowsData);
    this.permittedCommodities = [];
    this.pushingPC.forEach(element => {
      this.permittedCommodities.push({ label: element, value: element })
    });
    this.sortedCols.forEach(o => {
      const filteredCol = this.filteredCols.find(fc => fc.field === o.field);
      const filteredColIndex = this.filteredCols.indexOf(filteredCol);
      if ((filteredColIndex == -1 || filteredColIndex > index)) {
        o.options = [];
        this.getFilterObject(rowsData, o.field).forEach(filteredobj => {
          if (filteredobj == 'Blanks') o.options.push({ label: filteredobj, value: '' });
          else o.options.push({ label: filteredobj, value: filteredobj });
        });
      }
    });
  }

  onRemoveChip(event) {
    this.doFilter([], event.field);
    this.dt._columns.forEach(col => {
      if (col.field == event.field) col.selectedOptions = [];
    });
  }

  ngAfterViewInit(): void {
    let selectedVal = [];
    if (this.dt) {
      if (localStorage.getItem('columns')) {
        this.selectedCols = JSON.parse(localStorage.getItem('columns'));
        this.sortedCols.forEach(element => {
          this.selectedCols.forEach(elm => {
            if (element.field === elm.field) {
              if (elm.selectedOptions.length != 0) {
                element.selectedOptions = elm.selectedOptions;
                selectedVal.push(element);
              } else {
                selectedVal.push(element);
              }

            }

          });
        });
        this.selectedCols = selectedVal;
        this.selectedCols.forEach(col => {
          if (col.field === 'permittedCommodity') {
            this.filter(col.selectedOptions)
          } else {
            this.dt.filter(col.selectedOptions, col.field, 'in');
          }
        });
      };
      this.sortColumns();
    }
  }
  ngAfterViewChecked(): void {
    let selectedVals = [];
    if (this.dt && this.tableUpdate) {
      this.tableUpdate = false;
      if (localStorage.getItem('columns')) {
        this.sortedCols.forEach(element => {
          this.selectedCols.forEach(elm => {
            if (element.field === elm.field) {
              selectedVals.push(element);
            }

          });
        });
        this.selectedCols = selectedVals;
        this.selectedCols.forEach(col => {
          if (col.field === 'permittedCommodity') {
            this.filter(col.selectedOptions)
          } else {
            this.dt.filter(col.selectedOptions, col.field, 'in');
          }
        });
        this.sortColumns();
      };
    }
  }
  onChangeDDValue(event) {

    let isRailcarID = event.value.find(x => x.field == 'railcarID') == undefined ? false : true;
    if (!isRailcarID) {
      this.sortedCols.forEach(element => {
        if (element.field == 'railcarID') {
          this.selectedCols.push(element);
        }
      });
    }

    if (event.value.length == 0) {
      this.sortedCols.forEach(element => {
        if (element.field == 'railcarID') {
          this.selectedCols.push(element);
        }
      });
    } else {
      if (event.itemValue && event.itemValue.field == 'railcarID') {
        if (isRailcarID) {
          this.sortedCols.forEach(element => {
            if (element.field == 'railcarID') {
              this.selectedCols.push(element);
            }
          });
        }
      }
    }
    this.sortColumns();
  }

  showHideData(e) {
    this.cols = e;
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1;
      let value2;
      if (event.field == 'fixedAsset' || event.field == 'contract' || event.field == 'monthlyDepreciation') {
        value1 = Number(data1[event.field]);
        value2 = Number(data2[event.field]);
      } else if (event.field == 'expiration' || event.field == 'effective' || event.field == 'lastUpdatedDate' || event.field == 'onHire') {
        value1 = data1[event.field] != '' ? Date.parse(data1[event.field]) : 0
        value2 = data2[event.field] != '' ? Date.parse(data2[event.field]) : 0
      }
      else {
        value1 = data1[event.field];
        value2 = data2[event.field];
      }

      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.order * result);
    });
    localStorage.setItem('columns', JSON.stringify(this.selectedCols));
  }

  sortColumns() {
    this.selectedCols = this.selectedCols.sort((a, b) => {
      return a.serialNo - b.serialNo;
    })
  }
  getallrailfleetData(userId, role) {
    this.spinner.show();
    const pgPayLoad = {
      railFleetID: 0,
      userID: userId,
      isAdmin: role
    }
    this._railFleetService.getallrailfleetData(pgPayLoad).subscribe(response => {
      this.apiResp = response;
      this.activeCarsData = this.apiResp;
      this.activeCarsData.forEach(x => (x.editMode = false));
      this.orgActiveCarsData = JSON.parse(JSON.stringify(this.activeCarsData));
      console.log(this.activeCarsData)
      this.filteredData = JSON.parse(JSON.stringify(this.activeCarsData));
      this.pushingPC = this.getMultiSelect(this.activeCarsData);
      this.pushingPC.forEach(element => {
        this.permittedCommodities.push({ label: element, value: element })
      });
      console.log(this.permittedCommodities)
      this.sortedCols.forEach(o => {

        this.getFilterObject(this.activeCarsData, o.field).forEach(filteredobj => {
          if (o.options.filter(x => x.value == filteredobj).length == 0) {
            if (filteredobj == 'Blanks')
              o.options.push({ label: filteredobj, value: '' });
            else
              o.options.push({ label: filteredobj, value: filteredobj });
          }
        })
      });
      this.doFilter([], "");
      this.spinner.hide();
    })
  }
  getFilterObject(fullObj, key) {
    const uniqChk = [];
    let blanks = false;
    fullObj.filter((obj) => {
      if (!uniqChk.includes(obj[key])) {
        if (obj[key] !== null && obj[key] !== '')
          uniqChk.push(obj[key]);
        else if (!uniqChk.includes('Blanks')) {
          blanks = true;
        }
      }
      return obj;
    });
    const numericColumns = ['ageofCar', 'carBuilderID', 'contract', 'dueTest', 'fixedAsset', 'hazardClassID', 'leaseTerm', 'maxGrossWeight', 'packingGroupID', 'permittedCommoditiesID', 'railFleetID', 'rent', 'rentalAgreement', 'rep_Maint', 'tareWeightLBS', 'term', 'yearBuilt', 'loadVolumn']
    const dateColumns = ['effective', 'expiration', 'lastUpdatedDate', 'onHire'];
    if (dateColumns.includes(key)) {
      uniqChk.sort(function (a, b) {
        //const aComps = a.split("/");
        //const bComps = b.split("/");
        let aDate = new Date(a);
        let bDate = new Date(b);
        return  aDate.getTime() - bDate.getTime();
      });
    } else if (numericColumns.includes(key)) {
      uniqChk.sort((a, b) => {
        return ((Number(a) < Number(b)) ? -1 : 1)
      });
    } else {
      uniqChk.sort();
    }
    if (blanks) uniqChk.unshift('Blanks');
    return uniqChk;
  }
  getMultiSelect(fullObj) {
    const uniqChk = [];
    fullObj.forEach(element => {
      element.permittedCommodity.forEach(b => {
        if (!uniqChk.includes(b.permittedcommodities)) {
          if (b.permittedcommodities != null && b.permittedcommodities !== '')
            uniqChk.push(b.permittedcommodities);
        }
      });
    });
    return uniqChk;
  }

  onnSelectRow(rowData, type) {
    console.log("select row", rowData, type)
    this.router.navigate(['rail-car/info'], { state: { data: { cols: this.cols, rowData: rowData, selectionType: type } } });
  }
  filterData(value, filter) {
    if (value == '') {
      if (this.filteredFields.length) {
        this.filteredFields = this.filteredFields.filter(ff => ff.fieldName !== filter);
      } else {
        this.filteredFields = [];
      }
    } else {
      let foundField = this.filteredFields.filter(ff => ff.fieldName == filter);
      if (foundField.length) {
        this.filteredFields.forEach(ff => {
          if (ff.fieldName == filter) {
            ff.value = value;
          }
        })
      } else {
        let fieldObj = {
          fieldName: filter,
          value: value
        };
        this.filteredFields.push(fieldObj);
      }
    }
    if (this.filteredFields.length) {
      let temp = this.activeCarsData;
      this.filteredFields.forEach(ff => {
        temp = temp.filter(fd => fd[ff.fieldName].toUpperCase().includes(ff.value.toUpperCase()));
      })
      this.filteredData = temp;
    } else {
      this.filteredData = JSON.parse(JSON.stringify(this.activeCarsData));
    }
    this.tempFilteredData = JSON.parse(JSON.stringify(this.filteredData));
  }

  filter(value) {
    var record = [];
    let temp = this.filteredData;
    if (value.length !== 0) {
      temp.forEach(element => {
        element.permittedCommodity.forEach(pc => {

          if (value.includes(pc.permittedcommodities)) {
            if (!record.includes(element)) {
              if (element !== null && element !== '') {
                record.push(element);
              }
            }
          }
        });
      });
      this.activeCarsData = record
    } else {
      this.activeCarsData = temp;
    }
    return this.activeCarsData;
  }
  showAllSpecsDetails(element: any) {
    this._railFleetService.emailAllSpecs(element.railcarID).subscribe((res: any) => {
      this.apires = res;
      const blob = this.b64toBlob(this.apires, 'application/pdf');
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    })
  }
  b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  exportExcel() {
    let data = this.dt.hasFilter() ? this.dt.filteredValue : this.dt.value;
    let tFilteredData = JSON.parse(JSON.stringify(data));
    tFilteredData.forEach(ac => {
      let pcValue = "";
      if (ac.permittedCommodity.length > 0) {
        ac.permittedCommodity.forEach((pc, index) => {
          pcValue = pcValue + pc.permittedcommodities + (index < ac.permittedCommodity.length - 1 ? "," : '')
        });
        ac.permittedCommodity = pcValue;
      }
    })
    this.exportAsExcelFile(tFilteredData, 'RailCarFleet', 'sheet1', this.selectedCols)
  }
  exportAsExcelFile(json: any[], excelFileName: string, sheetName: string, columnValues: any[]): void {
    var fieldValues: any[] = columnValues.map(field => field.field);
    var headerValues: string[] = columnValues.map(header => header.header);
    var displayedJson: any[] = [];
    if (json != null && json.length > 0) {
      for (let originalValue of json) {
        var jsonObject: any = {};
        for (let fieldValue of fieldValues) {
          jsonObject[fieldValue] = originalValue[fieldValue];
        }
        var jsonString = JSON.stringify(jsonObject);
        displayedJson.push(JSON.parse(jsonString));
      }
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(displayedJson);
      var range = XLSX.utils.decode_range(worksheet['!ref']);
      for (var C = range.s.c; C <= range.e.c; ++C) {
        var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
        if (!worksheet[address]) continue;
        worksheet[address] = {
          v: headerValues[C],
          t: 's',
          s: {
            alignment: { textRotation: 90 },
            font: { bold: true }
          }
        };
      }
      XLSX.utils.sheet_add_aoa(worksheet, [[]], { origin: -1 });
      const workbook: XLSX.WorkBook = { Sheets: { [sheetName]: worksheet }, SheetNames: [sheetName] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    }

  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
  editBulk() {
    this.bulkEdit = true;
  }
  reset(dt) {
    // if (localStorage.getItem('columns')) {

    this.selectedCols.forEach(col => {
      if (col.field === 'permittedCommodity') {
        col.selectedOptions = [];
        this.filter([]);
      } else {
        col.selectedOptions = [];
      }
    });
    // }
    localStorage.clear();

    dt.reset();
    this.filteredCols = [];
    this.selectedCols = [];
    this.sortedCols.forEach(element => {
      if (element.field != 'hazardClass' && element.field != 'packingGroup'
        && element.field != 'carStatus' && element.field != 'dueTest'
        && element.field != 'originalRCID' && element.field != 'permittedCommodity'
        && element.field != 'originalCertofConstruction' && element.field != 'carBuilder'
        && element.field != 'ageofCar' && element.field != 'onHire'
        && element.field != 'monthlyDepreciation' && element.field != 'term'
      )
        this.selectedCols.push(element);
    });
    localStorage.setItem('columns', JSON.stringify(this.selectedCols));
    this.sortColumns();
    this.doFilter([], '', true);
    this.updateMultiSelectDD(this.orgActiveCarsData, 0);
  }
  bulkEditStatus(status) {
    this.bulkEdit = status;
    if (!this.bulkEdit) {
      this.tableUpdate = true;
    }
    this.getallrailfleetData(this.userInfo[0].userID, this.Arole);
    // const pgPayLoad = {
    //   railFleetID: 0,
    //   userID: this.userInfo[0].userID,
    //   isAdmin: this.Arole
    // }
    // this._railFleetService.getallrailfleetData(pgPayLoad).subscribe(response => {
    //   this.apiResp = response;
    //   this.activeCarsData = this.apiResp;
    //   this.filteredData = JSON.parse(JSON.stringify(this.activeCarsData));
    //   this.pushingPC = this.getMultiSelect(this.activeCarsData);
    // })
    this.selectedValues = [];
  }

  permittedCommoditiesEdit = []
  getAllMasterData() {
    this._railFleetService.getRailMasterData().subscribe(response => {
      this.masterAPIResp = response;
      this.permittedCommoditiesOpts = this.masterAPIResp.permittedCommoditiesOpts;
      if (this.permittedCommoditiesOpts != undefined)
        this.permittedCommoditiesOpts.forEach(pc => {
          // pc.railcarID = this.rowData.railcarID;
          pc.permittedCommoditiesDesc = null;
        })
      this.permittedCommoditiesEdit = this.masterAPIResp.permittedCommodities;
      this.permittedCommoditiesEdit.forEach(pc => {
        // pc.railcarID = this.rowData.railcarID;
        pc.permittedCommoditiesDesc = null;
      })
      this.packingGroups = this.masterAPIResp.packagingGroups;
      this.hazardClasses = this.masterAPIResp.hazardClasses;
      console.log(this.packingGroups, this.hazardClasses)
      this.carTypes = this.masterAPIResp.carTypes;
      this.dot_aar_classes = this.masterAPIResp.dot_aar_classes;
      this.carBuilders = this.masterAPIResp.carBuilders;
      if (this.selectedValues != undefined)
        this.selectedValues.forEach((val, i) => {
          this.selectedValues[i].permittedCommodity = this.permittedCommoditiesOpts.filter(pc => this.selectedValues[i].permittedCommodity
            .find(selectedPc => selectedPc.permittedCommoditiesID === pc.permittedCommoditiesID));
          this.selectedValues[i].hazardClassSelected = this.hazardClasses.find(hc => hc.hazardClassID === this.selectedValues[i].hazardClassID);
          this.selectedValues[i].packinggroupSelected = this.packingGroups.find(hc => hc.packinggroup === this.selectedValues[i].packingGroup);
          this.selectedValues[i].carbuilderSelected = this.carBuilders.find(hc => hc.carBuilderID === this.selectedValues[i].carBuilderID);
        })
    })
  }
}
